.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.inputContainer > span {
  font-size: 14px;
  color: rgb(255, 44, 44);
}

.saveUser {
  color: #575e73;
  font-size: 14px;
  font-weight: 400;
}

.forgotPassword {
  color: #3561fe;
  font-size: 14px;
}

.inputContainerFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.loginBtnContainer {
  display: flex;
  justify-content: center;
  padding: 16px 0px;
}

.loginBtnContainer > button {
  width: auto !important;
  min-width: 40%;
}

.saveUserContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.forgotPassword:hover {
  cursor: pointer;
}

@media (max-width: 1024px) {
  .inputContainer {
    width: 90%;
  }

  .saveUser,
  .forgotPassword {
    font-size: 10px;
  }

  .inputContainerFooter {
    font-size: 10px;
  }

  .loginBtnContainer {
    width: 100%;
  }
}
