.container {
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.innerContainer {
  width: 100%;
  display: flex;
}

.contentContainer,
.imageContainer {
  min-width: 50%;
  max-height: 100vh;
}

.successMessage {
  color: #008000;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}

.errorMessage {
  color: #ff0000;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}

.contentContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.imageContainer {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imageContainer > img {
  width: 100%;
}

.content {
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.welcomeContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 16px;
}

.title {
  color: #101010;
  font-size: 24px;
  font-weight: 700;
}

.description {
  color: #545f71;
  font-size: 15px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.recoveryBtnContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media (max-width: 1024px) {
  .imageContainer {
    display: none;
  }

  .welcomeContainer {
    width: 80%;
  }
  .title {
    font-size: 18px;
  }

  .description {
    font-size: 12px;
  }

  .contentContainer {
    width: 100%;
  }

  .content {
    align-items: center;
    width: 100%;
    gap: 24px;
  }

  .form {
    width: 90%;
    gap: 24px;
  }
}
