.selectContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

label {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #000000cc;
}

.selectWrapper {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

.selectField {
  width: 100%;
  height: 48px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #d3dce6;
  border-radius: 8px;
  background-color: #fff;
  cursor: pointer;
  user-select: none;
  transition: border-color 0.2s ease;
}

.selectField:hover {
  border-color: #b0b7c3;
}

.selectField.active {
  border-color: #3561fe;
}

.placeholder {
  color: #9ca3af;
}

.selectedText {
  color: #000000;
}

.iconButton {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6b7280;
}

.arrow {
  width: 20px;
  height: 20px;
  transition: transform 0.2s ease;
}

.arrow.open {
  transform: rotate(180deg);
}

.optionsContainer {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #d3dce6;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.option {
  padding: 12px 8px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.option:hover {
  background-color: #f3f4f6;
}

.option.selected {
  background-color: #ecf0ff;
  color: #3561fe;
}

.hiddenSelect {
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0;
  pointer-events: none;
}
