.container {
  width: 100%;
  padding-top: 60px;
}

.videoLecturesTitleContainer {
  display: flex;
  align-items: center;
  gap: 11px;
}

.videoLecturesTitleButton {
  cursor: pointer;
}

.videoLecturesTitle {
  color: #1d2026;
  font-size: 36px;
  line-height: 43.2px;
}

.videoContainer {
  /* max-width: fit-content; */
  /* max-height: 672px; */
  /* margin: 0 auto; */
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 36px;
  border-radius: 24px;
  overflow: hidden;
}

.videoLessonsInnerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.certificateButtonContainer {
  width: 30%;
}

.videoContainer > video {
  max-height: 672px;
  height: 100%;
}

.videoLessonsContainer {
  width: 100%;
  background: #eff2fa;
  border-radius: 24px;
  padding: 30px;
  box-sizing: border-box;
  margin-top: 32px;
}

.videoLessonsCompletionContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}
/* 
.tabSelectorContainer {
  width: 80%;
} */
.videoLessonsCompletionContainer > div:last-child {
  width: 30%;
}

.videoLessonsCompletionInnerContainer {
  display: flex;
  justify-content: space-between;
}

.videoLessonsCompletionTitle {
  color: #3561fe;
  font-size: 14px;
  font-weight: 600;
  line-height: 25.2px;
  letter-spacing: 1.3px;
}

.videoLessonsCompletionProgressBar {
  display: flex;
  gap: 3px;
}

.videoLessonsCompletionProgressLine {
  width: 85px;
  height: 5px;
  background-color: #3561fe;
}

.videoLessonsAccordionContainer {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
}

.videoLessonsAccordionTab {
  width: 100%;
  padding: 16px 20px;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.videoLessonsAccordionTab svg {
  cursor: pointer;
  width: 10px;
  height: 10px;
  margin-left: 5px;
}

.videoLessonsAccordionTabTitle {
  line-height: 19.2px;
  font-weight: 600;
}

.videoLessonsAccordionTabInnerContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.videoLessonsAmount,
.videoLessonsDuration {
  color: #555555;
  line-height: 24px;
}

.videoLessonsDuration {
  margin-left: 10px;
}

.videoLessonsAccordionToggle {
  width: 100%;
  background-color: #ffffff;
  padding: 12px 0;
  padding-right: 20px;
  padding-left: 45px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.videoLessonsAccordionToggleTab {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.videoLessonsAccordionToggleTabTitle {
  line-height: 27px;
}

.videoLessonsAccordionQuizBtn {
  padding: 4px 12px;
  background: #3561fe;
  border: 0;
  border-radius: 8px;
  cursor: pointer;
  color: #ffffff;
  font-size: 16px;
  line-height: 24px;
}

.videoLessonsAccordionQuizTime {
  color: #555555;
  line-height: 24px;
  margin-left: 33px;
}

.videoLessonsAccordionIconContainer {
  margin-left: 10px;
}

.syllabusItem {
  display: flex;
  height: 32px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px 12px 20px;
}

.syllabusItemInnerContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: 60%;
}

.syllabusInfoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.quizzBtn {
  background-color: #3561fe;
  border-radius: 8px;
  padding: 4px 12px;
  color: #ffffff;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  border: 0;
}

.quizzModalHeaderContainer {
  background-color: #0a033c;
  color: white;
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.quizzModalHeaderTitle {
  color: white;
  font-weight: 600;
}

.quizzModalCloseIcon {
  width: 18px;
  height: 18px;
}

.quizzModalCloseIcon > svg {
  width: 100%;
  height: 100%;
  fill: white;
  cursor: pointer;
}

.quizzModalButtons > button:first-child {
  width: 102px;
  border: 0;
  border-radius: 8px;
  color: #1d2026;
  font-size: 16px;
  font-weight: 600;
  line-height: 48px;
  cursor: pointer;
}

.quizzModalButtons > button:last-child {
  display: flex;
  align-items: center;
  gap: 12px;
}

.quizzModalQuestionsContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 48px;
  padding: 0 24px;
}

.quizzModalQuestionContainer {
  border: 1px solid #475569;
  border-radius: 6px;
  overflow: hidden;
}

.quizzModalQuestionHeader {
  padding: 16px 20px;
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: #0a033c;
}

.quizzModalQuestionNumber {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #475569;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  flex-shrink: 0;
}

.quizzModalQuestionText {
  color: #ffffff;
  font-size: 18px;
  line-height: 28px;
}

.quizzModalQuestionAnswers {
  background-color: #eff2fa;
  padding: 20px 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.quizzModalQuestionAnswer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.quizzModalQuestionAnswer > input {
  width: 16px;
  height: 16px;
}

.quizzModalQuestionAnswerText {
  color: #010101;
  font-size: 14px;
  line-height: 24px;
}

.quizzModalButtons {
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
}

.cancelButton {
  padding: 8px 16px;
  border: none;
  background-color: #f5f7fa;
  border-radius: 8px;
  cursor: pointer;
  transition: opacity 0.2s ease;
}

.cancelButton:hover {
  opacity: 0.7;
}

.quizResultContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 24px;
  text-align: center;
}

.quizResultTitle {
  font-size: 24px;
  font-weight: 600;
  color: #0a033c;
  margin-bottom: 16px;
}

.quizResultScore {
  font-size: 18px;
  color: #1d2026;
  margin-bottom: 8px;
}

.quizResultPercentage {
  font-size: 36px;
  font-weight: 700;
  color: #3561fe;
  margin-bottom: 24px;
}

.certificateButton {
  width: 100%;
  padding: 12px 24px;
  background-color: #f8f9fa;
  border: 2px solid #3561fe;
  border-radius: 10px;
  color: #3561fe;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.certificateButton:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #3561fe;
  transition: all 0.3s ease;
  z-index: -1;
}

.certificateButton:hover {
  color: white;
}

.certificateButton:hover:before {
  width: 100%;
}

@media (max-width: 1024px) {
  .videoLessonsInnerContainer {
    display: flex;
    flex-direction: column-reverse;
    gap: 30px;
    justify-content: space-between;
  }

  .certificateButtonContainer {
    width: 100%;
  }
  video {
    width: 100vw;
  }

  .videoLecturesTitle {
    font-size: 24px;
  }

  .videoLessonsContainer {
    background-color: white;
    margin-top: 0;
    padding: 24px 0;
  }

  .videoLessonsCompletionContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 10px;
    box-sizing: border-box;
  }

  .videoLessonsCompletionContainer > div:last-child {
    width: 100%;
  }

  .videoLessonsAccordionContainer {
    border: 1px solid #0000001a;
    border-radius: 16px;
  }

  .quizzBtn {
    background-color: #3561fe;
    border-radius: 8px;
    padding: 2px 12px;
    color: #ffffff;
    font-size: 12px;
    line-height: 24px;
    cursor: pointer;
    border: 0;
  }

  .duration,
  .syllabusItemInnerContainer {
    font-size: 12px;
  }

  .quizzModalQuestionText {
    font-size: 16px;
    line-height: 24px;
  }
}
