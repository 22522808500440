.textareaContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

label {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #000000cc;
}

.textarea {
  height: 117px;
  border: 1px solid #d3dce6;
  padding: 18px 16px;
  border-radius: 8px;
  font-size: 15px;
  line-height: 18.11px;
  color: #00000099;
  outline: none;
  resize: none;
}
