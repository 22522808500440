.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.inputContainer > span {
  font-size: 14px;
  color: rgb(255, 44, 44);
}

.inputDescriptionMessage {
  color: rgb(139, 139, 139);
  font-size: 14px;
}

.acceptRules {
  color: #575e73;
  font-size: 14px;
  font-weight: 400;
}

.forgotPassword {
  color: #3561fe;
  font-size: 12px;
}

.termsAndConditionsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.registerBtnContainer {
  display: flex;
  justify-content: center;
  padding: 16px 0px;
}

.registerBtnContainer > button {
  width: auto !important;
  min-width: 40%;
}

.acceptRulesContainer {
  display: flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 10px;
}

.validationList {
  display: flex;
  flex-direction: row;
  gap: 10%;
  width: 100%;
  justify-content: center;
  padding: 0%;
  font-size: 14px;
}

@media (max-width: 1024px) {
  .inputContainer {
    width: 90%;
  }

  .acceptRules,
  .forgotPassword {
    font-size: 10px;
  }

  .termsAndConditionsContainer {
    font-size: 10px;
  }

  .registerBtnContainer {
    width: 100%;
  }

  .inputDescriptionMessage {
    font-size: 10px;
  }
}
