.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000cc;
  backdrop-filter: blur(2px);
  z-index: 1000;
}

.modalContainer {
  width: 100%;
  position: absolute;
  background-color: white;
  border-radius: 8px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 1024px) {
  .modalContainer {
    width: 94%;
  }
}
