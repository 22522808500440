.subscribeInputContainer {
  height: 60px;
  display: flex;
}

.subscribeInput {
  width: 367px;
  border: 0;
  outline: none;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  color: #00000099;
  padding: 0 20px;
}

.subscribeButton {
  padding: 0 32px;
  background-color: #3561fe;
  border: none;
  cursor: pointer;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  color: #ffffff;
}

@media (max-width: 1024px) {
  .subscribeInputContainer {
    height: 60px;
  }

  .subscribeInput {
    max-width: 367px;
    width: 100%;
    font-size: 14px;
  }
}
