.container {
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 30vw;
}

.borderedContainer {
  border: 1px solid #eaeaea;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 1vw;
}

.imageContainer {
  width: 100%;
  position: relative;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (9 / 16 = 0.5625 or 56.25%) */
}

.cardImageWeb {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.contentContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80%;
}

.title {
  font-weight: 700;
  font-size: 20px;
}

.durationInfo,
.studentsInfo,
.reviewInfo {
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: row;
  gap: 8px;
  font-size: 16px;
}

.priceContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
  font-size: 18px;
}

.price {
  color: #9d9d9d;
  text-decoration: line-through;
}

.discountedPrice {
  color: #55be24;
}

.wishListButton {
  background-color: #3561fe29;
  border-radius: 8px;
  padding: 12px;
  border: none;
  width: 48px;
  height: 48px;
  transition: background-color 0.2s ease;
  cursor: pointer;
}

.wishListButton:hover svg,
.wishListButton:active svg {
  fill: #3561fe;
  color: #3561fe;
}

.wishListButton:hover {
  background-color: #3561fe40;
}

.wishListButton:active {
  background-color: #3561fe60;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.details {
  color: #3561fe;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
}

.discountedPriceDetails {
  font-size: 32px;
}

.priceDetails {
  font-size: 24px;
}

@media (max-width: 1024px) {
  .container {
    width: 90%;
  }
  .cardImageWeb {
    display: block;
  }

  .durationInfo,
  .studentsInfo,
  .reviewInfo {
    font-size: 14px;
  }

  .info {
    width: 95%;
  }

  .discountedPriceDetails {
    font-size: 20px;
  }

  .priceDetails {
    font-size: 12px;
  }
}
