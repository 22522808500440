.coursesContainer {
  margin-top: 40px;
}

.coursesTitle {
  color: #1d2026;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.01em;
}

.cardsContainer {
  margin-top: 24px;
  margin-bottom: 32px;
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 24px;
}

.paginationContainer {
  display: flex;
  justify-content: center;
}
@media (max-width: 1024px) {
}

@media (max-width: 1024px) {
  .coursesContainer {
    margin-top: 32px;
  }

  .coursesTitle {
    font-size: 16px;
  }

  .cardsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
}
