.videoContainer {
  border-radius: 15px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  aspect-ratio: 16 / 9;
  display: flex;
  justify-content: center;
}

video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: '100%';
  height: '100%';
  border-radius: '15px';
  overflow: 'hidden';
  border: 'none';
  background: 'transparent';
  --controls-backdrop-color: 'transparent';
  --media-object-fit: 'cover';
  --media-border-radius: '15px';
  --container-border-radius: '15px';
}

@media (max-width: 1024px) {
  .videoContainer {
    width: 100%;
  }
}
