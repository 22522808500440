.primary {
  padding: 14.5px 24px;
  background-color: #3561fe;
  border: none;
  border-radius: 12px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 16px;
  font-family: 'Helvetica Neue';
  box-shadow: 0px 4px 12px 0px #3561fe8a;
}

.primary:hover {
  background-color: #3561fe5c;
}

.secondary {
  padding: 14.5px 24px;
  border: 1px solid #3561fe;
  border-radius: 12px;
  color: #545f71;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 16px;
  font-family: 'Helvetica Neue';
}

.secondary:hover {
  background-color: rgba(65, 105, 225, 0.1);
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed;
  pointer-events: none;
}
