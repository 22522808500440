.container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.title {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
}

.reviewsList {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.reviewItem {
  display: flex;
  align-items: center;
  gap: 8px;
}

.count {
  color: #555555;
  font-size: 14px;
}
