.syllabusItem {
  display: flex;
  height: 32px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 40px 12px 20px;
}

.syllabusItemInnerContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.syllabusInfoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.quizzBtn {
  background-color: #3561fe;
  border-radius: 8px;
  padding: 4px 12px;
  color: #ffffff;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  border: 0;
}
