.header {
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: #eff2fa;
  padding: 0px 5vw;
}

.container {
  margin: 0 auto;
  width: 100%;
  height: 70px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.logo > svg {
  cursor: pointer;
  height: 100%;
  width: 100%;
  height: 54px;
}

.logo {
  height: 54px;
}

.userLoggedIcon {
  cursor: pointer;
}

.userLoggedIcon > img {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  overflow: hidden;
}

.navList {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 2vw;
  margin: 0;
  padding: 0;
}

.navList li {
  text-decoration: none;
  font-size: 16px;
  transition: color 0.3s ease;
  cursor: pointer;
}

.navListLiActive {
  color: #4169e1;
}

.navList li:hover {
  color: #4169e1;
}

li {
  color: #00000099;
}
.authButtons {
  display: flex;
  gap: 24px;
  align-items: center;
}

.registerBtn {
  padding: 14.5px 24px;
  border: 1px solid #3561fe;
  border-radius: 12px;
  color: #545f71;
  text-decoration: none;
  transition: all 0.3s ease;
  cursor: pointer;
}

.burgerMenu {
  display: none;
}

.registerBtn:hover {
  background-color: rgba(65, 105, 225, 0.1);
}

.loginBtn {
  padding: 14.5px 24px;
  background-color: #4169e1;
  border-radius: 12px;
  color: white;
  text-decoration: none;
  transition: all 0.3s ease;
  box-shadow: 0px 4px 24px 0px #3561fe5c;
  cursor: pointer;
}

.loginBtn:hover {
  background-color: #3555b8;
}
.userMenuContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.dropdownHeaderContainer {
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e9eaf0;
}

.dropdownHeaderTitle {
  color: #1d2026;
  font-size: 20px;
  line-height: 26px;
}

.dropdownHeaderBtn {
  color: #4e5566;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
}

.dropdownCoursesContainer {
  margin-top: 12px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
}

.dropdownCourseContainer {
  padding: 12px 20px;
  display: flex;
  gap: 12px;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
}

.dropdownCourseContainer:hover {
  background-color: #f5f7fa;
}

.dropdownCourseIcon {
  width: 32px;
  height: 32px;
  background-color: #3561fe;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.dropdownCourseInfo {
  width: 100%;
}

.dropdownCourseName {
  color: #1d2026;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
}

.dropdownCourseProgress {
  margin-top: 6px;
}

.dropdownCourseProgressTitle {
  color: #3561fe;
  font-size: 14px;
  line-height: 25.2px;
  letter-spacing: 2%;
}

.dropdownCourseTime {
  margin-top: 6px;
  color: #8c94a3;
  font-size: 12px;
  line-height: 16px;
}

.userIcon {
  display: none;
}

.userDropdownWrapper {
  position: relative;
}

.userMenuDropdown {
  padding: 8px 0;
  min-width: 100%;
  border-radius: 24px;
  overflow: hidden;
}

.userMenuDropdownItem {
  padding: 1vw;
  color: #1d2026;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  white-space: nowrap;
  border-radius: 0;
}

.userMenuDropdownItem:hover {
  background-color: #f5f7fa;
  color: #3561fe;
}

/* Desktop/mobile visibility classes */
.desktopOnly {
  display: block;
  width: auto;
  min-width: 15vw;
  right: 0;
}

@media (max-width: 1024px) {
  .nav {
    display: flex;
    justify-content: space-between;
  }

  .header {
    padding: 0px 16px;
  }

  .container > div:first-child {
    order: 2;
    width: 120px;
  }

  .container > div:last-child {
    order: 2;
  }

  .navList {
    display: none;
  }

  .userLoggedIcon > img {
    width: 40px;
    height: 40px;
  }

  .burgerMenu {
    display: block;
    cursor: pointer;
    font-size: 24px;
  }

  .authButtons {
    display: none;
  }

  .userMenuContainer > button {
    display: none;
  }

  .userIcon {
    width: 35px;
    height: 35px;
    display: block;
    order: 2;
  }

  .userIcon > svg {
    width: 100%;
    height: 100%;
  }

  .userDropdownWrapper {
    position: static;
  }

  .desktopOnly {
    display: none;
  }
}
