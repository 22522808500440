.courseReviewHeaderContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: 12px;
}

.reviewPoint {
  color: black;
  font-weight: 600;
  font-size: 36px;
  line-height: 43.2px;
}

.reviewAmount {
  font-size: 16px;
  line-height: 24px;
  margin-top: 4px;
}

.reviewPersonPhotoContainer > img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.reviewPercentageTabsContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.reviewPercentageTabContainer {
  display: flex;
  align-items: center;
}

.reviewPercentageTabContainer > div:first-child > svg {
  width: 16px;
  height: 16px;
}

.reviewPercentage {
  width: 48px;
  margin-left: 8px;
  margin-right: 17px;
}

.reviewPercentageEmptyLine {
  width: 100%;
  height: 10px;
  background-color: #eaeaea;
}

.reviewPercentageFilledLine {
  background-color: #f6b40a;
  height: 100%;
}

.reviewPeopleContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.reviewPersonContainer {
  padding-top: 20px;
  border-top: 1px solid #eaeaea;
  display: flex;
  gap: 20px;
}

.reviewPersonPhotoContainer {
  width: 60px;
  height: 60px;
  flex-shrink: 0;
}

.reviewPersonCommentContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.reviewPersonCommentInfo {
  display: flex;
  justify-content: space-between;
}

.reviewPersonCommentName {
  color: black;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
}

.reviewPersonCommentDate {
  line-height: 24px;
  font-size: 16px;
}

.paginationContainer {
  margin-top: 20px;
}

.videoLessonsReviewBtn {
  width: 180px;
  height: fit-content;
  background-color: #3561fe29;
  border: 0;
  border-radius: 8px;
  cursor: pointer;
  color: #3561fe;
  font-size: 16px;
  line-height: 24px;
  padding: 4px 0;
}

.reviewModalHeaderContainer {
  padding: 16px 20px;
  box-shadow: 0px -1px 0px 0px #e9eaf0 inset;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reviewModalHeaderTitle {
  color: #1d2026;
  line-height: 22px;
}

.reviewModalCloseIcon {
  width: 18px;
  height: 18px;
}

.reviewModalCloseIcon > svg {
  width: 100%;
  height: 100%;
  fill: #8c94a3;
  cursor: pointer;
}
.reviewModalMainContainer {
  padding: 24px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reviewModalMainTitle {
  display: flex;
  gap: 7px;
}

.reviewModalAmount {
  color: #1d2026;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.reviewModalRate {
  color: #8c94a3;
  font-size: 18px;
  line-height: 24px;
}

.reviewModalStars {
  display: flex;
  gap: 2px;
  margin-top: 12px;
}

.reviewModalStars > div {
  width: 48px;
  height: 48px;
  cursor: pointer;
}

.reviewModalFeedbackForm {
  width: 100%;
  margin-top: 24px;
}

.reviewModalFeedbackForm > div {
  gap: 6px;
}

.reviewModalFeedbackForm > div > label {
  font-size: 14px;
}

.reviewModalFeedbackForm > div > textarea {
  height: 100px;
}

.reviewModalFeedbackButtons {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
}

.reviewModalFeedbackButtons > button:first-child {
  width: 102px;
  border: 0;
  border-radius: 8px;
  color: #1d2026;
  font-size: 16px;
  font-weight: 600;
  line-height: 48px;
  cursor: pointer;
}

.reviewModalFeedbackButtons > button:last-child {
  display: flex;
  align-items: center;
  gap: 12px;
}

.cancelButton {
  padding: 8px 16px;
  border: none;
  background-color: #f5f7fa;
  border-radius: 8px;
  cursor: pointer;
  transition: opacity 0.2s ease;
}

.cancelButton:hover {
  opacity: 0.7;
}

@media (max-width: 1024px) {
  .reviewPersonComment {
    font-size: 12px;
    line-height: 18px;
  }

  .reviewModalHeaderContainer {
    padding: 28px 20px;
    padding-bottom: 16px;
    box-shadow: 0px -1px 0px 0px #e9eaf0 inset;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
