.container {
  width: 100%;
  position: absolute;
  top: 100%;
  right: 0;
  border: 1px solid #e9eaf0;
  border-radius: 24px;
  box-shadow: 0px 4px 20px 0px #1d20261f;
  background-color: #ffffff;
  z-index: 999;
  margin-top: 10px;
}

@media (max-width: 1024px) {
  .container {
    top: 6%;
    right: 12%;
  }
}
