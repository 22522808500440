.tabContainer {
  display: inline-flex;
  padding: 4px;
  background-color: #ecf0ff;
  border-radius: 8px;
}

.tab {
  width: 15rem;
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  color: #4b5563;
  background: transparent;
  cursor: pointer;
  transition: all 0.2s ease;
}

.tab:hover {
  color: #1f2937;
}

.tab.active {
  background-color: #3561fe;
  color: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

/* Make sure the buttons don't inherit unwanted styles */
.tab:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.5);
}

/* Add some spacing between tabs */
.tab + .tab {
  margin-left: 4px;
}

/* Ensure proper text alignment */
.tab {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1024px) {
  .tab {
    width: 8rem;
    padding: 6px 12px;
    font-size: 12px;
  }
}
