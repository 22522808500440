.container {
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  gap: 80px;
}

.uploadPhotoContainer {
  width: fit-content;
  height: fit-content;
  border: 1px solid #e9eaf0;
  border-radius: 8px;
  padding: 44px;
  display: flex;
  max-width: 280px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.uploadPhotoImgContainer {
  position: relative;
  width: 238px;
  height: 238px;
  border-radius: 8px;
  overflow: hidden;
}

.uploadPhotoImgContainer img {
  width: 100%;

  height: 100%;
  object-fit: cover;
}

.uploadOverlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  padding: 12px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.uploadIcon {
  display: flex;
  align-items: center;
  gap: 8px;
  color: white;
  font-size: 14px;
}

.uploadPhotoText {
  color: #64748b;
  font-size: 14px;
  margin-top: 12px;
  text-align: center;
}

.formsContainer {
  max-width: 800px;
  width: 100%;
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.errorMessage {
  font-size: 14px;
  color: rgb(255, 44, 44);
  margin-top: 2px;
}

.twoInputContainer {
  display: flex;
  align-items: flex-end;
  gap: 20px;
}

.inputContainer {
  width: 100%;
}

.inputContainer > div {
  gap: 8px;
}

.formTitle {
  color: #1d2026;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -1%;
  font-weight: 500;
  margin-top: 54px;
  margin-bottom: 24px;
}

@media (max-width: 1024px) {
  .container {
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }

  .twoInputContainer {
    flex-direction: column;
  }
}
