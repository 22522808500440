@font-face {
  font-family: 'Abhaya Libre';
  src: url('../../fonts/AbhayaLibre-SemiBold.ttf') format('opentype');
  font-style: normal;
  font-display: swap;
}

.footer {
  width: 100%;
  padding: 50px 5vw 10px 5vw;
  background-color: #0a033c;
  box-sizing: border-box;
}

.logo {
  color: #3561fe;
  font-size: 30px;
  text-decoration: none;
  line-height: 35.39px;
  font-family: 'Abhaya Libre';
}

.footerInfoContainer {
  display: flex;
  justify-content: space-between;
}

.footerSectionContainer {
  max-width: 250px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.footerSectionTitle {
  font-size: 18px;
  font-weight: 500;
  line-height: 21.73px;
  color: #ffffff;
}

.footerSectionContainer:nth-child(2) > .footerSectionText {
  align-self: flex-start;
  cursor: pointer;
}

.footerSectionText {
  text-decoration: none;
  color: #ffffff99;
  font-size: 14px;
  line-height: 16.9px;
  display: flex;
  align-items: center;
  gap: 8px;
  opacity: 0.8;
}

.footerSectionText > svg {
  flex-shrink: 0;
}

.footerMediaContainer {
  padding-top: 32px;
  border-top: 1px solid #ffffff2a;
  margin-top: 54px;
}

.footerMediaInnerContainer {
  display: flex;
  justify-content: space-between;
}

.footerRightsText {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.04px;
  color: #ffffff;
  opacity: 75%;
}

@media (max-width: 1024px) {
  .footer {
    padding: 50px 16px 10px 16px;
  }

  .footerInfoContainer {
    flex-direction: column;
    gap: 24px;
  }

  .footerMediaContainer {
    margin-top: 24px;
    padding-top: 24px;
  }

  .footerMediaInnerContainer {
    flex-direction: column-reverse;
    gap: 24px;
  }

  .footerRightsText {
    font-size: 12px;
  }
}
