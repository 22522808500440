.adBanner {
  width: 100%;
  background-color: #f5f7fa;
  overflow: hidden;
  text-align: center;
}

.adBanner img {
  width: 100%;
  height: 70px;
  object-fit: cover;
}

@media (max-width: 1024px) {
  .adBanner img {
    height: 50px;
  }
}
