.container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0 10%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.errorTitle {
  width: 100%;
  color: #000000;
  font-size: 36px;
  font-weight: 600;
  line-height: 43.2px;
}

.errorImgContainer {
  width: 50%;
  height: 50%;
  display: flex;
  justify-content: center;
}
@media (max-width: 1024px) {
  .container {
    padding: 0 16px;
    gap: 50px;
  }
  .errorImgContainer {
    width: 100%;
    height: 100%;
  }
}
