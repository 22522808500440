.dividerContainer {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.line {
  flex: 1;
  height: 1px;
  background-color: #e5e7eb;
}

.text {
  color: #6b7280;
  font-size: 14px;
  padding: 0 10px;
}
