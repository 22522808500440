@font-face {
  font-family: 'Helvetica Neue';
  src: url('./fonts/Helvetica\ Neue\ LT\ GEO\ 65\ Medium.ttf')
    format('opentype');
  font-style: normal;
  font-display: swap;
}

body {
  font-family: 'Helvetica Neue';
  margin: 0;
  overflow-x: hidden;
}

button {
  padding: 0;
}
.mainContainer {
  height: 100%;
  padding: 0px 5vw;
}

.container {
  flex: 1 0 auto;
  padding: 0px 0px 80px 0px;
  justify-content: center;
}

html,
body,
#root,
.outerContainer {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.header-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  padding-top: 70px;
}

.header-mobile-background {
  display: none;
}
@media (max-width: 1024px) {
  html,
  body {
    overflow-x: hidden;
  }
  .mainContainer {
    padding: 0px 16px;
  }

  .container {
    padding-bottom: 10%;
  }
  .header-background {
    display: none;
  }

  .header-mobile-background {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    padding-top: 70px;
  }
}
