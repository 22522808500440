.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

label {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #000000cc;
}

.inputWrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.input {
  width: 100%;
  padding-right: 40px; /* Make room for the icon */
  height: 48px;
  border: 1px solid #d3dce6;
  padding: 0 16px;
  border-radius: 8px;
  font-size: 15px;
  line-height: 18.11px;
  color: #00000099;
  outline: none;
}

.inputError {
  border: 1px solid rgb(255, 44, 44);
}

.iconButton {
  position: absolute;
  right: 16px;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.iconButton svg {
  width: 20px;
  height: 20px;
  color: #666;
}
