.sideBarContainer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 34px 0px 60px 0px #00000014;
  transition: all 1s ease-in-out;
  display: none; /* Hide by default on desktop */
}

@media (max-width: 1024px) {
  .sideBarContainer {
    display: flex; /* Show on mobile */
  }
}

.sideBarInnerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 70px;
}

.closeIcon {
  width: 28px;
  height: 28px;
  margin-right: 16px;
  cursor: pointer;
}

.closeIcon > svg {
  width: 100%;
  height: 100%;
  fill: #6c7072;
}

.sideBarLogo {
  color: #3561fe;
  font-size: 30px;
  text-decoration: none;
  cursor: pointer;
  margin-left: 16px;
}

.sideBarLinksContainer {
  border-top: 1px solid #0000001a;
}

.sideBarLink {
  width: 100%;
  padding: 24px 16px;
  border-bottom: 1px solid #0000001a;
  font-size: 18px;
  line-height: 17.91px;
  color: #00000099;
  font-weight: 400;
  cursor: pointer;
  transition: color 0.5s ease-in-out;
  box-sizing: border-box;
}

.sideBarLink:hover {
  color: #3561fe;
}

.active {
  color: #3561fe;
  background-color: #3561fe29;
  border: none;
}

.authButtons {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  margin: 0 16px;
  margin-top: 32px;
}

.authButtons > button {
  width: 100%;
}

.authenticatedUserContainer {
  padding: 16px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: max-content;
  align-content: center;
}

.userIcon > img,
.userIcon > svg {
  width: 68px;
  height: 68px;
}

.userName {
  font-weight: 700;
  line-height: 16px;
  color: #0f0f0f;
  margin-top: 20px;
}

.logOutButton {
  margin: 0 16px;
  margin-top: 24px;
}

.logOutButton > button {
  width: 100%;
  border: 1px solid #ea4335;
  color: #ea4335;
  background-color: #ea433529;
}
