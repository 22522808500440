.messengerContainer {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.messengerButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  text-decoration: none;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  z-index: 2;
  will-change: transform;
}

.messengerButton:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transform: scale(1.05);
}

.messengerIcon {
  width: 40px;
  height: 40px;
}

.messageBubble {
  position: absolute;
  bottom: 80px;
  right: 0;
  background-color: white;
  color: #333;
  padding: 12px 16px;
  border-radius: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 250px;
  animation: fadeIn 0.3s ease-in-out;
  font-size: 14px;
  display: flex;
  align-items: flex-start;
  z-index: 1;
}

.bubbleContent {
  flex: 1;
  padding-right: 8px;
}

.closeButton {
  background: none;
  border: none;
  color: #999;
  font-size: 18px;
  cursor: pointer;
  padding: 0 4px;
  line-height: 1;
}

.closeButton:hover {
  color: #666;
}

.messageBubble:after {
  content: '';
  position: absolute;
  bottom: -8px;
  right: 30px;
  width: 15px;
  height: 15px;
  background-color: white;
  transform: rotate(45deg);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.05);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Facebook ჩატის ვიჯეტის გადაფარვა */
:global(.fb_dialog) {
  right: 18pt !important;
  bottom: 18pt !important;
}

:global(.fb-customerchat) {
  right: 18pt;
  bottom: 18pt;
}

@media (max-width: 1024px) {
  .messengerButton {
    width: 60px;
    height: 60px;
  }

  .messengerIcon {
    width: 35px;
    height: 35px;
  }

  .messageBubble {
    font-size: 13px;
    padding: 10px 14px;
    max-width: 220px;
    bottom: 70px;
  }
}
