.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 25px;
  padding: 54px 0px 80px 0px;
}
.sidebarContainer {
  width: 30%;
}

.filterHeaderContainer {
  display: none;
}

.filterButtonContainer {
  display: none;
}

.contentContainer {
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.searchContainer {
  width: 100%;
}
.content {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
}
.title {
  color: #000000;
  font-size: 36px;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.sortContainer {
  width: 160px;
  height: 40px;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 0px 10px;
  font-weight: 400;
  color: #00000099;
  justify-content: flex-end;
}

.headerSortContainer {
  display: flex;
  align-items: center;
  width: 30vw;
}

.paginationContainer {
  display: flex;
  justify-content: center;
}

.filterContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.filterTitle {
  font-size: 20px;
}

.loaderContainer {
  width: 100%;
  height: 100%;
}

.loaderContent {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1024px) {
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .container {
    flex-direction: column;
  }

  .sidebarContainer {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #ffffff;
    z-index: 1000;
    box-shadow: 0px 4px 9px 0px #98a0b440;
    padding: 16px;
    box-sizing: border-box;
    overflow-y: auto;
  }

  .filterContainer {
    max-width: 40%;
  }

  .filterHeaderContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
  }

  .filterHeaderTitle {
    color: #4b4b4b;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }

  .filterHeaderButtons {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .filterHeaderResetAll {
    color: #3561fe;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
  }

  .filterHeaderCloseBtn {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .filterHeaderCloseBtn > svg {
    width: 100%;
    height: 100%;
    fill: #4b4b4b;
  }

  .filterButtonContainer {
    display: block;
    margin-top: 30px;
  }

  .contentContainer {
    width: 100%;
  }

  .headerContainer {
    flex-direction: column;
    gap: 25px;
  }

  .headerSortContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .searchContainer {
    width: 100%;
  }

  .title {
    font-size: 18px;
  }

  .sortContainer {
    width: 100%;
  }
}
